
import { defineComponent, inject, PropType, Ref, ref } from 'vue'

import { minValue, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import { ProductRepositoryKey } from '@/data/injectables'
import { CreateProductParams, ProductForm } from '@/data/product/types'
import { ProductVariation } from '@/data/product-variation/types'
import { UserLogData } from '@/data/auth/types'

import BlockUI from '@/components/BlockUI.vue'
import FormField from '@/components/FormField.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import UserLog from '@/components/UserLog.vue'

export default defineComponent({
  components: { NavBar, FormField, BlockUI, UserLog },
  props: {
    productId: {
      type: Number as PropType<number>,
      default: null,
    },
  },
  setup: () => {
    const productRepository = inject(ProductRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)

    const form = ref<ProductForm>({
      name: undefined,
      priority: undefined,
      inactive: true,
    })

    const variations = ref<ProductVariation[]>([])

    const v$ = useFormValidation(form)

    return {
      form,
      variations,
      v$,
      ...toast,
      productRepository,
      handleError,
    }
  },
  data() {
    return {
      saving: false,
      deleting: false,
      loading: false,
      userLog: {} as UserLogData,
      submitted: false,
    }
  },
  computed: {
    editing(): boolean {
      return !!this.productId
    },
    title(): string {
      return this.editing ? 'Editar produto' : 'Criar produto'
    },
    busy(): boolean {
      return this.loading || this.saving || this.deleting
    },
    variationsBadge(): string {
      return String(this.variations?.length || 0)
    },
  },
  created() {
    if (this.editing) {
      this.loadFormAndUserLog()
    }
  },
  methods: {
    openVariations(): void {
      this.$router.push({
        path: `/products/${this.productId}/variations`,
      })
    },
    async handleSave(): Promise<void> {
      this.submitted = true
      if (this.v$.$invalid) return

      this.saving = true
      if (this.editing) {
        await this.updateProduct()
      } else {
        await this.createProduct()
      }
      this.saving = false
    },
    async handleDelete(): Promise<void> {
      this.$confirm.require({
        message: 'Confirma a deleção do produto?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.deleteProduct()
        },
      })
    },
    async updateProduct(): Promise<void> {
      try {
        await this.productRepository?.updateProduct(this.productId, this.form)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Produto editado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async createProduct(): Promise<void> {
      try {
        await this.productRepository?.createProduct(
          this.form as CreateProductParams
        )
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Produto criado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async deleteProduct(): Promise<void> {
      try {
        this.deleting = true
        await this.productRepository?.deleteProduct(this.productId)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Produto deletado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.deleting = false
      }
    },
    async loadFormAndUserLog(): Promise<void> {
      try {
        this.loading = true
        const { createdBy, modifiedBy, variations, ...form } =
          (await this.productRepository?.getProduct(this.productId)) || {}

        this.form = form
        this.variations = variations || []
        this.userLog = {
          createdBy,
          modifiedBy,
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
  },
})

function useFormValidation(form: Ref<ProductForm>) {
  const validationState = {
    form,
  }

  const validationRules = {
    form: {
      name: {
        required,
      },
      priority: {
        required,
        minValue: minValue(0),
      },
    },
  }

  return useVuelidate(validationRules, validationState)
}
